@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
:root {
  color: #c9c9c9;
  background-color: #191919;
  height: 100vh;
}

.leaflet-bottom,
.leaflet-control,
.leaflet-pane,
.leaflet-top {
  z-index: 0 !important;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #191919;
}

::-webkit-scrollbar-thumb {
  background: #212123;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #212123 #191919;
}
