/* styles.css */
.slick-dots li {
  width: 0 !important;
  height: 10px !important;
}

.dots {
  margin: 0;
  padding: 0;
  list-style: none;
}
